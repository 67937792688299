import { Pagination, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { SearchCart } from "./searchCart";
import axios from "../../../helpers/interseptor";
import { useAppSelector } from "../../../helpers/store.hook";
import i18next from "../../../i18n/config";
import { CarRoutesPath } from "../../../routes";
import { CarItem } from "../../car/CarTypes";

const ContainerStyled = styled.div`
  max-width: 1222px;
`;

const ListStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: flex-start;
`;

const OrderWrapperContainerStyled = styled.div`
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TotalCarFoundStyled = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 175%;
`;

const OrderWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const OrderFilterItemStyled = styled.div<{ $Width?: number }>`
  min-width: ${(props) => props?.$Width || "250"}px;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const OrderSelectStyled = styled(Select)<{ onChange: (value: string) => void }>`
  width: 100%;
  margin-left: 14px;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  height: 40px;
`;

const PaginationStyled = styled(Pagination)`
  display: flex;
  justify-content: center;

  & .ant-pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .ant-pagination-item-active {
    background: rgb(116, 99, 232);
    border: none;
    &:hover {
      border: none;
      & a {
        color: rgb(255, 255, 255);
      }
    }

    & a {
      color: rgb(255, 255, 255);
    }
  }

  & .ant-pagination-item-link {
    border: 0.83px solid rgb(219, 219, 219) !important;
    border-radius: 8px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    &:hover {
      background: var(--main-color) !important;
      & svg {
        & * {
          fill: rgb(250, 250, 250) !important;
        }
      }
    }
  }

  & .ant-pagination-item-link {
    & .ant-pagination-item-container {
      width: 100%;
    }
  }
`;

const PaginationWrapperStyled = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  margin-bottom: 34px;
`;

const orderOptions = [
  { value: "d", label: i18next.t("order.default") },
  { value: "p-l-t", label: i18next.t("order.price-low-top") },
  { value: "p-t-l", label: i18next.t("order.price-top-low") },
  { value: "y-l-t", label: i18next.t("order.year-low-top") },
  { value: "y-t-l", label: i18next.t("order.year-top-low") },
  { value: "m-l-t", label: i18next.t("order.mileage-low-top") },
  { value: "m-t-l", label: i18next.t("order.mileage-top-low") },
  { value: "publication", label: i18next.t("order.publication-date") },
];

const periodOptions = [
  { value: "all", label: i18next.t("order.all") },
  { value: "12-h", label: i18next.t("order.last-12-h") },
  { value: "day", label: i18next.t("order.last-day") },
  { value: "2-d", label: i18next.t("order.last-2-days") },
  { value: "week", label: i18next.t("order.last-week") },
  { value: "2-w", label: i18next.t("order.last-2-week") },
  { value: "month", label: i18next.t("order.last-month") },
];

const pageSizeOptions = [10, 20, 50, 100];

export const SearchList = () => {
  const { t } = useTranslation();
  const sort = useAppSelector((state) => state.filters.sort);
  const period = useAppSelector((state) => state.filters.period);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalCars, setTotalCars] = useState(1);
  const [cars, setCars] = useState<CarItem[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    axios
      .get<any>(`search/cars?${searchParams}`)
      .then((res) => res.data)
      .then((data) => {
        setCars(data.cars);
        setTotalCars(data.totalCars);
        setPage(data.page);
        setLimit(data.limit);
      });
  }, [searchParams]);

  const handleNext = (page: number) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setSearchParams((prev) => {
      prev.set("page", String(page));
      return prev;
    });
  };

  const handleShowSize = (current: number, size: number) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setSearchParams((prev) => {
      prev.set("limit", String(size));
      return prev;
    });
  };

  const handlePerPage = (value: number) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setSearchParams((prev) => {
      prev.set("limit", String(value));
      return prev;
    });
  };

  const handleSort = (value: string) => {
    setSearchParams((prev) => {
      prev.set("sort", value);
      return prev;
    });
  };
  const handlePeriod = (value: string) => {
    setSearchParams((prev) => {
      prev.set("period", value);
      return prev;
    });
  };

  return (
    <ContainerStyled>
      <OrderWrapperContainerStyled>
        <TotalCarFoundStyled>
          {t("searchList.total-count", { count: totalCars })}
        </TotalCarFoundStyled>
        <OrderWrapperStyled>
          <OrderFilterItemStyled $Width={290}>
            <OrderSelectStyled onChange={handleSort} value={sort} options={orderOptions} />
          </OrderFilterItemStyled>
          <OrderFilterItemStyled>
            <OrderSelectStyled onChange={handlePeriod} value={period} options={periodOptions} />
          </OrderFilterItemStyled>
        </OrderWrapperStyled>
      </OrderWrapperContainerStyled>
      <ListStyled>
        {cars.map((car) => (
          <SearchCart
            {...car}
            key={car._id}
            _id={car._id}
            link={`${CarRoutesPath.car}/${car._id}`}
          />
        ))}
      </ListStyled>
      <PaginationWrapperStyled>
        <PaginationStyled
          onChange={handleNext}
          total={totalCars}
          current={page}
          defaultPageSize={limit}
          onShowSizeChange={handleShowSize}
          showSizeChanger={false}
        />
        <Select value={limit} onChange={handlePerPage}>
          {pageSizeOptions.map((size) => (
            <Select.Option key={size} value={size}>
              {size} {t("searchList.items-per-page")}
            </Select.Option>
          ))}
        </Select>
      </PaginationWrapperStyled>
    </ContainerStyled>
  );
};
