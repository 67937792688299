import { Card, Carousel } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { DRIVE_TYPE, FUEL, GEAR, MILEAGE } from "../../../constants/AdditionalInfo";
import DriveType from "../asset/driveType.svg";
import FuelIcon from "../asset/fuel.svg";
import GearIcon from "../asset/gear.svg";
import MileageIcon from "../asset/mileage.svg";

const CardStyled = styled(Card)`
  width: 386px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  background: rgb(255, 255, 255);
  & .ant-card-body {
    padding: 16px;
  }
`;

const GeneralInfoStyled = styled.div`
  padding: 16px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CarNameStyled = styled.h3`
  text-transform: uppercase;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0;
  margin-bottom: 8px;
`;

const ModelGenerationStyled = styled.div`
  color: rgb(34, 34, 34);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  & svg {
    margin: 0 5px;
  }
`;

const PriceStyled = styled.h2`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 26px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0;
  margin: 16px 0;
`;

const YearCitiStyled = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const YearStyled = styled.div`
  color: rgb(250, 250, 250);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  border-radius: 4px;
  background: rgb(116, 99, 232);
  padding: 8px;
  margin-bottom: 4px;
`;

const CityStyled = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
`;

const Devider = styled.div`
  border: 1px solid rgb(219, 219, 219);
  width: 100%;
`;

const CarInfoStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
`;

const InfoItem = styled.div`
  border-radius: 4px;
  padding: 8px;
  background: rgb(240, 240, 240);
  display: flex;
  align-items: center;
`;

const InfoItemText = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
`;

const InfoItemIcon = styled.div`
  margin-right: 8px;
  display: flex;
  & img {
    width: 17px;
  }
`;

const DetailButton = styled(Link)`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  color: rgb(61, 61, 61);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: rgb(116, 99, 232) !important;
    color: rgb(250, 250, 250);
    background: rgb(116, 99, 232);
  }
`;

const infoItems: [typeof GEAR, typeof MILEAGE, typeof DRIVE_TYPE, typeof FUEL] = [
  GEAR,
  MILEAGE,
  DRIVE_TYPE,
  FUEL,
];

const icons: Record<typeof GEAR | typeof MILEAGE | typeof DRIVE_TYPE | typeof FUEL, string> = {
  [GEAR]: GearIcon,
  [MILEAGE]: MileageIcon,
  [DRIVE_TYPE]: DriveType,
  [FUEL]: FuelIcon,
};

const settings: any = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

const CarouselWrapperStyled = styled.div`
  height: 300px;
  // & > div {
  //   height: 100%;
  // }
  // & .slick-slide {
  //   height: 100%;
  //   & > div {
  //     height: 100%;
  //   }
  // }
  // & .slick-slider {
  //   height: 100%;
  // }

  // & .slick-list {
  //   height: 100%;
  // }
  // & .slick-track {
  //   height: 100%;
  // }
  // & .carousel-container {
  //   height: 100%;
  // }
`;

const ThumbnailCarouselImageStyled = styled.div<{ $src?: string }>`
  // background-image: url("${(props) => props.$src}");
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: contain;
  // width: 100%; /* adjust as needed */
  // height: 300px; /* adjust as needed */
  display: grid;
  place-items: center;
  width: 100%;
  height: 300px; /* Set the desired container height */
  overflow: hidden;

  & img {
    width: 100%;
    height: auto;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
`;

const ArrowStyled = styled.div<{ $right: number }>`
  &.slick-arrow {
    width: 36px;
    height: 28px;
    border: 1px solid rgb(248, 248, 248);
    border-radius: 4px;
    background: rgb(248, 248, 248);
    top: auto;
    bottom: 20px;
    right: ${(prop) => prop.$right}px;
    left: auto;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: rgb(116, 99, 232);
      border: 1px solid rgb(116, 99, 232);
      & svg path {
        fill: rgb(250, 250, 250);
      }
    }
    &:before {
      display: none;
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SampleNextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <ArrowStyled className={className} onClick={onClick} $right={16}>
      <svg
        width="6.062500"
        height="10.000000"
        viewBox="0 0 6.0625 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="chevron_right"
          d="M3.93 5L0 1.06L1.06 0L6.06 5L1.06 10L0 8.93L3.93 5Z"
          fill="#1C1B1F"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </svg>
    </ArrowStyled>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SamplePrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <ArrowStyled className={className} onClick={onClick} $right={60}>
      <svg width="6.062500" height="10.000000" viewBox="0 0 6.0625 10" fill="none">
        <path
          id="chevron_right"
          d="M2.12 5L6.06 1.06L5 0L0 5L5 10L6.06 8.93L2.12 5Z"
          fill="#1C1B1F"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </svg>
    </ArrowStyled>
  );
}

//TODO add power
export const SearchCart = ({
  _id,
  image,
  title,
  brand,
  model,
  modelView,
  photo,
  link,
  footer = <></>,
  ...rest
}: any) => {
  const { t } = useTranslation();
  //TODO SLIDER CREATES LAGS
  return (
    <>
      <CardStyled>
        {/* <CarouselWrapperStyled>
          <Carousel {...settings} swipe>
            {photo.map((src: string) => (
              <ThumbnailCarouselImageStyled key={src} className="slide-container" $src={src}>
                <img src={src} alt={`${brand} ${model}`} />
              </ThumbnailCarouselImageStyled>
            ))}
           
          </Carousel>
        </CarouselWrapperStyled> */}
        <GeneralInfoStyled>
          <div>
            <CarNameStyled>
              {brand} {modelView}
            </CarNameStyled>
            <Link to={link}>
              <ModelGenerationStyled>
                {rest.generationView}
                <svg
                  width="6.000000"
                  height="6.000000"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    id="Ellipse 8"
                    cx="3.000000"
                    cy="3.000000"
                    r="3.000000"
                    fill="#BFBFBF"
                    fillOpacity="1.000000"
                  />
                </svg>
                {rest.modificationView}
              </ModelGenerationStyled>
            </Link>
          </div>
          <YearCitiStyled>
            <YearStyled>{rest.year}</YearStyled>
            <Link to={link}>
              <CityStyled>{rest.region}</CityStyled>
            </Link>
          </YearCitiStyled>
        </GeneralInfoStyled>
        <Devider />
        <PriceStyled>$ {rest.price}</PriceStyled>
        <CarInfoStyled>
          {infoItems.map((item) => (
            <InfoItem key={item}>
              <InfoItemIcon>
                <img src={icons[item]} alt={item} />
              </InfoItemIcon>
              <InfoItemText>
                {item === MILEAGE
                  ? `${Number(rest.mileage)?.toLocaleString()} ${t("km")}`
                  : t(rest[item])}
              </InfoItemText>
            </InfoItem>
          ))}
        </CarInfoStyled>
        <DetailButton to={link}>{t("card.details")}</DetailButton>
        {footer}
      </CardStyled>
    </>
  );
};
