import { InputNumber, Slider, SliderSingleProps } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FromTo, FromToEnum } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";

const PricePickWrapperStyled = styled.div`
  margin: 0 auto;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
`;

const WrapperStyled = styled.div`
  border-radius: 8px;
  padding: 16px;
  background: rgb(255, 255, 255);
  width: 100%;
`;

const TitleStyled = styled.div`
  color: rgb(46, 46, 46);
  font-size: 18px;
  font-weight: 600;
  line-height: 175%;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InputBetweenTextStyled = styled.div`
  color: rgb(0, 0, 0);

  margin: 0 16px;
`;

const InputNumberStyled = styled(InputNumber)<{ onChange: (value: number | null) => void }>`
  flex: 1;
  height: 55px;
  display: flex;
  border: 1px solid rgb(219, 219, 219);

  & .ant-input-number-input-wrap {
    width: 100%;

    & input {
      height: 100%;
      color: rgb(0, 0, 0);
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0;
      text-align: center;
    }
  }
`;

const SliderWrapperStyled = styled.div`
  width: 100%;
  margin: 50px auto;
`;

const TooltipStyled = styled.div`
  padding: 8px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
`;

const marks: SliderSingleProps["marks"] = {
  0: 0,

  10000: "10000",

  25000: "25000",
  50000: "50000",
  100000: "100000",
};

export const PricePick = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [price, setPrice] = useState<FromTo<number>>({ from: 0, to: 0 });

  const priceValue = useAppSelector((state) => state.filters.selectedData.price);

  useEffect(() => {
    if (priceValue) {
      setPrice(priceValue);
    }
  }, [priceValue]);

  const handleInputChange = (type: FromToEnum) => (value: number | null) => {
    if (value === null) return;

    dispatch(FiltersActions.setPrice({ type, value }));
  };

  const handleChangeComplete = (value: number[]) => {
    dispatch(FiltersActions.setPriceSlider(value));
    setPrice({ from: value[0], to: value[1] });
  };

  const handleChange = (value: number[]) => {
    setPrice({ from: value[0], to: value[1] });
  };
  const handleBlur = () => {
    if (price?.to && price?.from && price?.from > price?.to) {
      dispatch(FiltersActions.setPrice({ type: FromToEnum.TO, value: price.from }));
    }
  };

  return (
    <>
      <WrapperStyled>
        <TitleStyled>{t("price")}</TitleStyled>
        <PricePickWrapperStyled>
          <SliderWrapperStyled>
            <Slider
              marks={marks}
              range={{ draggableTrack: true }}
              value={[price?.from || 0, price.to || 0]}
              min={100}
              max={price?.to && price?.to > 110000 ? price?.to : 110000}
              step={100}
              onChange={handleChange}
              onChangeComplete={handleChangeComplete}
              tooltip={{
                formatter: (value) => <TooltipStyled>{value}</TooltipStyled>,
              }}
            />
          </SliderWrapperStyled>
          <InputWrapper>
            <InputNumberStyled
              min={100}
              max={450000}
              value={price?.from}
              step={100}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              onChange={handleInputChange(FromToEnum.FROM)}
              onBlur={handleBlur}
            />
            <InputBetweenTextStyled>{t("to")}</InputBetweenTextStyled>
            <InputNumberStyled
              min={100}
              max={500000}
              step={100}
              value={price?.to}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              onChange={handleInputChange(FromToEnum.TO)}
              onBlur={handleBlur}
            />
          </InputWrapper>
        </PricePickWrapperStyled>
      </WrapperStyled>
    </>
  );
};
