import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FromToEnum } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { useSearch } from "../../../utils/useSearch";
import { FromToWrapperStyled, InputNumberStyled } from "../../searchFilter";

export const PowerInline = () => {
  const dispatch = useAppDispatch();
  const { manageSearchDebounce } = useSearch();
  const selectedPower = useAppSelector((state) => state.filters.selectedData.power);
  const handleChangePower = (type: FromToEnum) => (value: string | null) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value as string) || value === "" || value === null) {
      dispatch(
        FiltersActions.setPowers({ type, value: (value as unknown as number) || undefined }),
      );

      manageSearchDebounce(`power${type.slice(0, 1).toUpperCase()}${type.slice(1)}`, value || "");
    }
  };
  return (
    <FromToWrapperStyled>
      <InputNumberStyled
        value={String(selectedPower?.from || "0")}
        onChange={handleChangePower(FromToEnum.FROM)}
        min={0}
      />
      <InputNumberStyled
        value={selectedPower?.to as unknown as string}
        onChange={handleChangePower(FromToEnum.TO)}
        min={0}
      />
    </FromToWrapperStyled>
  );
};
