import { InputNumber, Select } from "antd";
import { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { BodyTypeSelect } from "./filters/inline/bodyType";
import { BrandsSelect } from "./filters/inline/brands";
import { ColorPicker } from "./filters/inline/color";
import { DriveTypeSelect } from "./filters/inline/driveType";
import { FuelSelect } from "./filters/inline/fuel";
import { GearSelect } from "./filters/inline/gear";
import { Generations } from "./filters/inline/generation";
import { MileageInline } from "./filters/inline/mileage";
import { ModelSelect } from "./filters/inline/model";
import { PowerInline } from "./filters/inline/power";
import { PriceInline } from "./filters/inline/price";
import { UseInfoSelect } from "./filters/inline/useInfo";
import { useAppDispatch } from "../../../helpers/store.hook";
import { CarGetAdditionalInfoThunk } from "../../car/redux/car.thunks";
import { YearPickerComp } from "../../filters/components/years";
import { FromToEnum } from "../../filters/filters.types";

export interface SelectProps {
  onSelect?: (value: string, options: any) => void;
  onChange?: (value: string | string[]) => void;
  dropdownRender?: (originNode: ReactNode) => ReactNode;
}

interface SelectMultyProps extends Omit<SelectProps, "onChange"> {
  onChange?: (value: string[]) => void;
}
export const SelectStyled = styled(Select)<SelectProps>`
  width: 100%;

  border-radius: 8px;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  & .ant-select-selector {
    border: 1px solid rgb(219, 219, 219);
  }
`;

const SelectWrapperStyled = styled.div`
  margin-bottom: 14px;
`;
const SelectTitle = styled.div`
  color: rgb(52, 51, 57);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  margin-bottom: 8px;
`;
export const SelectStyledMulty = styled(SelectStyled)<SelectMultyProps>``;

export const FromToWrapperStyled = styled.div`
  display: flex;
  gap: 16px;
`;

const YearWrapperStyled = styled.div`
  height: 40px;
  & > div {
    height: 100%;
    & .ant-select-selector {
      border-radius: 8px !important;
    }
  }
`;

const ContainerStyled = styled.div`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  padding: 16px;
  background: rgb(255, 255, 255);
`;

export const InputNumberStyled = styled(InputNumber)<{ onChange?: (value: string) => void }>`
  width: 100%;
  height: 40px !important;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  font-size: 16px;

  & .ant-input-number-input-wrap {
    height: 100%;
    display: flex;
  }

  & input {
    color: rgb(46, 46, 46);
    font-family: Roboto !important;

    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
  }
`;

export const SearchFilter = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(CarGetAdditionalInfoThunk());
  }, [dispatch]);

  return (
    <ContainerStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.useInfo.label")}</SelectTitle>
        <UseInfoSelect />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.brand.label")}</SelectTitle>
        <BrandsSelect />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.model.label")}</SelectTitle>
        <ModelSelect />
      </SelectWrapperStyled>

      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.generation.label")}</SelectTitle>
        <Generations />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.price.label")}</SelectTitle>
        <PriceInline />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.year.label")}</SelectTitle>
        <YearWrapperStyled>
          <FromToWrapperStyled>
            <YearPickerComp type={FromToEnum.FROM} /> <YearPickerComp type={FromToEnum.TO} />
          </FromToWrapperStyled>
        </YearWrapperStyled>
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.mileage.label")}</SelectTitle>
        <MileageInline />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.power.label")}</SelectTitle>
        <PowerInline />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.bodyType.label")}</SelectTitle>
        <BodyTypeSelect />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.fuel.label")}</SelectTitle>
        <FuelSelect />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.driveType.label")}</SelectTitle>
        <DriveTypeSelect />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.gear.label")}</SelectTitle>
        <GearSelect />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.gear.label")}</SelectTitle>
        <ColorPicker />
      </SelectWrapperStyled>
    </ContainerStyled>
  );
};
