import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FromToEnum } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { useSearch } from "../../../utils/useSearch";
import { FromToWrapperStyled, InputNumberStyled } from "../../searchFilter";

export const PriceInline = () => {
  const dispatch = useAppDispatch();
  const { manageSearchDebounce } = useSearch();
  const selectedPrice = useAppSelector((state) => state.filters.selectedData.price);
  const handleChangePrice = (type: FromToEnum) => (value: string | null) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value as string) || value === "" || value === null) {
      dispatch(FiltersActions.setPrice({ type, value: (value as unknown as number) || undefined }));

      manageSearchDebounce(`price${type.slice(0, 1).toUpperCase()}${type.slice(1)}`, value || "");
    }
  };
  return (
    <FromToWrapperStyled>
      <InputNumberStyled
        value={String(selectedPrice?.from || "0")}
        onChange={handleChangePrice(FromToEnum.FROM)}
        step={100}
        min={0}
      />
      <InputNumberStyled
        value={selectedPrice?.to as unknown as string}
        onChange={handleChangePrice(FromToEnum.TO)}
        step={100}
        min={0}
      />
    </FromToWrapperStyled>
  );
};
