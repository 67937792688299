import { Carousel } from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CarRoutesPath } from "../../../../routes";
import { SearchCart } from "../../../search/components/searchCart";
import { CarItem } from "../../CarTypes";

const RecommendationWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  gap: 20px;
  width: 100%;
  & .ant-carousel {
    width: 100%;
  }
`;

const RecommendationTitleStyled = styled.h4`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0;
`;

const WIDTH = 390;

export const Recommendation: React.FC<{ carId: string }> = ({ carId }) => {
  const { t } = useTranslation();
  const recommendationRef = useRef<HTMLDivElement>(null);
  const [cars, setCar] = useState<CarItem[] | null>(null);
  const [slideToShow, setSlideToShow] = useState<number | undefined>(undefined);
  useEffect(() => {
    axios
      .get(`/car/recommendations/${carId}`)
      .then((res) => res.data)
      .then((data) => setCar(data));
  }, [carId]);

  useEffect(() => {
    const width = recommendationRef.current?.clientWidth;
    if (width) {
      const slides = Math.floor(width / WIDTH) > 6 ? 6 : Math.floor(width / WIDTH);
      setSlideToShow(slides);
    }
  }, []);
  console.log("car  ", cars);
  return (
    <RecommendationWrapperStyled ref={recommendationRef}>
      <RecommendationTitleStyled>{t("preview.recommendation")}</RecommendationTitleStyled>
      <Carousel arrows infinite={false} slidesToShow={slideToShow} dots={false}>
        {cars?.map((car) => (
          <SearchCart
            {...car}
            key={car._id}
            _id={car._id}
            link={`${CarRoutesPath.car}/${car._id}`}
          />
        ))}
      </Carousel>
    </RecommendationWrapperStyled>
  );
};
