export function debounce(func: any, delay: number) {
  // eslint-disable-next-line no-undef
  let timeoutId: NodeJS.Timeout;
  return function (this: any, ...args: any) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
